'use strict'

###*
 # @ngdoc service
 # @name mundoAuthentication.factory:MyUser

 # @description

###
angular
  .module 'mundoAuthentication'
  .factory 'MyUser', [
    'Restangular'
    (Restangular) ->
      MyUserBase = Restangular
        .service('me')

      MyUserBase
  ]
